import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    '10952074-f820-4512-8866-6191fbd4a152': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'b7a57148-2af6-4a69-82f8-122715ceaa0d': {
      // external-warehouse TEST
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    '8a89b646-7ba8-4f03-b220-fa0f8c9eebfa': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '3f452aba-1ce9-4e62-be2c-f69a9d9a610d': {
      // external-warehouse ACCT
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    'ab77a2b0-2dad-45b5-af53-e33642702b2b': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '3a34bba0-ada1-41d4-aca4-34764578a853': {
      // external-warehouse PROD
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '14+',
  },
})
