import type { AppointmentFormTypes as Types } from '@grandvisionhq/appointments'

export const consents: Types.ConsentOptIn[] = [
  {
    title: 'appointments.consent.userAgreement',
    value: 'userAgreement',
    required: true,
  },
]
export const optIns: Types.ConsentOptIn[] = [
  {
    title: 'appointments.optIn.marketingEmails',
    value: 'subscribeNewsletter',
  },
]
