import * as React from 'react'

import { AuthProvider, Routes } from '@grandvisionhq/auth'

type AuthConfig = React.ComponentProps<typeof AuthProvider>['settings']

/**
 * All routes are empty because MyAccount is disabled on this OpCo.
 * Unfortunately we cannot remove them entirely, because they are required types.
 *
 * TODO: find a proper way to make the auth settings optional in core-components when MyAccount is disabled.
 */

const routes: Routes = {
  createAccount: '',
  login: '',
  logout: '',
  orders: '',
  signup: '',
  profile: '',
  settings: '',
  resetPassword: '',
  linkExpired: '',
  setPassword: '',
  unlinkOrder: '',
  verify: '',
  wishlist: '',
}

export const getAuthConfig = (): AuthConfig => ({
  apiUrl: '',
  basePath: '',
  clientId: '',
  customDomain: '',
  domain: '',
  routes,
  checkoutRoute: '',
})
